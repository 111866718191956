import PopHomeView from "../MainView/MainHomeView";
import React from "react";
import { useQuery } from "@tanstack/react-query";

import LoadingAnimation from "../loading-spinner";
import { fetchVideos } from "./data-fetch";

function AppView() {
  const {
    data: videos,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["videos"],
    queryFn: fetchVideos,
    staleTime: 30000,
    cacheTime: 5 * 60 * 1000,
  });

  const memoizedData = React.useMemo(() => videos || [], [videos]);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return <div>Error loading videos: {error.message}</div>;
  }

  return <PopHomeView data={memoizedData} />;
}

export default AppView;
