import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  padding: 10px 0;
`;

const Text = styled.span`
  font-size: 1rem;
  letter-spacing: 1.42px;
  line-height: 1.08;
  padding: 4px 0px;
  white-space: nowrap;
  color: transparent;
  position: relative;
`;

const AnimatedLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: black;
  transform-origin: left center;
  animation: lineAnimation 2.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;

  @keyframes lineAnimation {
    0% {
      transform: scaleX(0);
      transform-origin: left;
    }
    24% {
      transform: scaleX(1);
      transform-origin: left;
    }
    25% {
      transform: scaleX(1);
      transform-origin: right;
    }
    49% {
      transform: scaleX(0);
      transform-origin: right;
    }
    50% {
      transform: scaleX(0);
      transform-origin: right;
    }
    74% {
      transform: scaleX(1);
      transform-origin: right;
    }
    75% {
      transform: scaleX(1);
      transform-origin: left;
    }
    100% {
      transform: scaleX(0);
      transform-origin: left;
    }
  }
`;

const LoadingAnimation = () => {
  return (
    <Container>
      <Text>Loading</Text>
      <AnimatedLine />
    </Container>
  );
};

export default LoadingAnimation;