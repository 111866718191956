import React, { useState } from "react";
import styled from "styled-components";
import HomeView from "../PopUpView/HomePopView";
import HoverVideoPlayer from "react-hover-video-player";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { Wrap } from "./styled-wrap";

function PopHomeView({ data }) {
  const [showHomeView, setShowHomeView] = useState(false);
  const [dataHomeView, setDataHomeView] = useState({ name: "" });

  const getHomeView = (data) => {
    setShowHomeView(true);
    setDataHomeView(data);
  };

  const hideHomeView = () => {
    setShowHomeView(false);
  };

  return (
    <Container
      as={motion.div}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {data.map((data) => (
        <Wrap onClick={() => getHomeView(data)} id={data.cssid} key={data.id}>
          {window.innerWidth > 768 ? (
            <HoverVideoPlayer
              videoSrc={data.videoForGif}
              preload="metadata"
              loop={true}
              muted={true}
            />
          ) : (
            <HoverVideoPlayer
              videoSrc={data.videoForGif}
              preload="metadata"
              loop={true}
              muted={true}
              playsInline={true}
              pausedOverlay={
                <img
                  src={data.videoCoverImage}
                  alt=""
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              }
            />
          )}
        </Wrap>
      ))}

      <HomeView
        show={showHomeView}
        onHide={hideHomeView}
        name={dataHomeView.videoName}
        vimeo={dataHomeView.linkForVimeo}
        text={dataHomeView.videoName}
        director={dataHomeView.videoDirectorName}
        setDesigner={dataHomeView.setDesign}
        gaffer={dataHomeView.Gaffer}
        postProduction={dataHomeView.PostProduction}
        producer={dataHomeView.producer}
        agency={dataHomeView.Agency}
        client={dataHomeView.Client}
        production={dataHomeView.Production}
        cc={dataHomeView.CC}
        sd={dataHomeView.SD}
        ms={dataHomeView.MusicSound}
        vo={dataHomeView.VO}
        artdp={dataHomeView.ArtDepartment}
        dop={dataHomeView.DOP}
        year={dataHomeView.Year}
        addrow1={dataHomeView.addrow1}
        addrow2={dataHomeView.addrow2}
        addrow3={dataHomeView.addrow3}
      />
    </Container>
  );
}

PopHomeView.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PopHomeView;

const Container = styled.div`
  padding: 30px 0px 26px;
  display: grid;
  grid-gap: 8px 8px;
  gap: 4px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  justify-content: center;
  place-items: center stretch;
  margin-left: 10%;
  margin-right: 10%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px 8px;
  }
`;
