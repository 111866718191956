import styled from "styled-components";
import React, { lazy } from "react";
import LoadingAnimation from "./loading-spinner.js";

const LazyAds = lazy(() => import("./Viewer/ViewAds.jsx"));

const Ads = (props) => {
  return (
    <Container>
      <React.Suspense fallback={<LoadingAnimation />}>
        <LazyAds />
      </React.Suspense>
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 250px);
  overflow-x: hidden;
  display: block;
  margin-top: 73px;
  padding: 0 calc(3.5vw + 5px);
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 16vh;
  }
  &:after {
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -1;
  }
`;

export default Ads;
