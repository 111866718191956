import { client } from "../../client";
import { cleanUpData } from "./clean-data";

export const fetchVideos = async () => {
  const response = await client.getEntries({
    content_type: "adVideo",
    order: "fields.secuence",
  });
  return cleanUpData(response.items).sort((a, b) => a.secuence - b.secuence);
};
