import styled from "styled-components";
import AppView from "./Viewer/View.jsx";
import React from "react";
import LoadingAnimation from "./loading-spinner.js";
const Home = (props) => {
  return (
    <Container>
      <React.Suspense fallback={<LoadingAnimation />}>
        <AppView />
      </React.Suspense>
    </Container>
  );
};

const Container = styled.main`
  position: relative;
  min-height: calc(100vh - 250px);
  overflow-x: show;
  display: block;
  margin-top: 73px;
  padding: 0 calc(3.5vw + 5px);
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 16vh;
  }

  &:after {
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -1;
  }
`;

export default Home;
