import styled from "styled-components";
export const Wrap = styled.div`
  display: grid;
  justify-self: stretch;
  ${(props) => props.ratio}
  object-fit:fill;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.25, 0.45, 0.45, 0.94) 0s;
  border: solid 1px rgba(1, 1, 1, 0);
  position: relative;
`;
